import { pageStyles } from "../../styles/style";
import Footer from "../../components/Footer";
import DownbarS from "../../components/down_footer_s";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import "../../styles/global.css";
import iso from "../../images/certifi/MANUTEPUL-ISO14001.webp";
import cer from "../../images/certifi/TRANS-CER.webp";

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul | ISO 14001</title>
        <meta
          name="keywords"
          content="impresa di pulizie milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di disinfestazioni"
        />
      </Helmet>
      <Navbar />
      <DownbarS />
      <Banner
        pageName="Certificazioni "
        prepageName="ISO"
        postpageName="14001"
      />
      <section id="service" className="service-section pt-75 pb-100">
        <div className="container">
          <div className="row">
            <div class="elementor-container elementor-column-gap-default">
              <div data-id="613b2d4" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="certified-div">
                    <h1 class="elementor-heading-title elementor-size-default">
                      Certificazione ecologica
                      <span>ISO 14001</span>
                    </h1>
                  </div>
                  <div
                    class="elementor-element elementor-element-65fe5e5 elementor-widget elementor-widget-text-editor"
                    data-id="65fe5e5"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="text-certified-div">
                      <p>
                        <strong>
                          Noi di Manutenpul siamo molto attenti all’ambiente
                        </strong>
                        , per questo ci siamo impegnati per ottenere la{" "}
                        <strong>certificazione</strong>
                        <strong>ISO 14001</strong>.<br />
                        <br />
                        L’International Organization for Standardization (ISO)
                        ha realizzato questa norma tecnica per regolamentare i
                        Sistemi di Gestione Ambientale&nbsp;(SGA). Per essere
                        certificati ISO 14001 bisogna soddisfare i requisiti di
                        gestione ambientale descritti nella normativa stessa.
                      </p>
                      <p>
                        La normativa è composta da sette sezioni dedicate ai
                        requisiti degli SGA precedute da 3 sezioni che svolgono
                        una funzione introduttiva alle successive.
                      </p>
                      <p>
                        Noi crediamo che il rispetto dell’ambiente sia
                        essenziale per avere un’aria più pulita e respirabile,
                        per vivere in luoghi piacevoli e sani, per tutelare il
                        futuro delle prossime generazioni. Per questi motivi
                        <strong>
                          utilizziamo prodotti ECOLABEL che sono pulenti,
                          profumati e innocui sia nei confronti di persone sia
                          di animali
                        </strong>
                        .
                      </p>
                      <p>
                        Siamo molto scrupolosi in ogni fase della pulizia e
                        abbiamo deciso di utilizzare solo prodotti, attrezzature
                        e macchinari dotati di schede tecniche. Questo è stato
                        un altro fattore che ci ha permesso di ottenere la&nbsp;
                        <strong>
                          certificazione ecologica ISO 14001 rilasciata da SMC
                        </strong>
                        &nbsp;in data 12/03/2017.
                      </p>
                      <p>
                        La&nbsp;ISO 14001&nbsp;è una&nbsp;norma internazionale
                        ad adesione volontaria, applicabile a qualsiasi
                        tipologia di Organizzazione pubblica o privata,
                        che&nbsp;specifica i requisiti di un sistema di gestione
                        ambientale. Secondo noi per un’
                        <strong>impresa di pulizie di Milano</strong>è molto
                        importante seguire le linee guida della norma allo scopo
                        di garantire non solo servizi di qualità ma anche il
                        rispetto di principi fondamentali come quelli vicini
                        all’ecologia.
                      </p>
                      <p>
                        È possibile integrare questa norma con altri sistemi di
                        gestione conformi a norme specifiche, ad esempio
                        la&nbsp;<strong>ISO 9001 </strong>che prende in esame i
                        sistemi di gestione della qualità, i quali influiscono
                        decisamente sulla soddisfazione del cliente.
                      </p>
                      <p>
                        <strong>
                          Per definire il sistema di gestione conforme alla ISO
                          14001 è necessario:
                        </strong>
                      </p>
                      <ul>
                        <li>&#x2022; 
                          Realizzare un’analisi ambientale, cioè raggiungere
                          un’approfondita conoscenza degli aspetti
                          ambientali&nbsp;(emissioni, uso risorse etc) che una
                          impresa deve effettivamente gestire, capire il quadro
                          legislativo e le prescrizioni applicabili all’azienda
                          e valutare la significatività degli impatti;
                        </li>
                        <li>&#x2022; Definire una Politica aziendale;</li>
                        <li>&#x2022; 
                          Definire responsabilità specifiche&nbsp;in materia
                          ambientale;
                        </li>
                        <li>&#x2022; 
                          Definire, applicare e mantenere attive le attività, le
                          procedure e le registrazioni previste dai requisiti
                          della 14001.
                        </li>
                      </ul>
                      <p>
                        <br />
                        Le attività di audit interno e di certificazione a
                        fronte della ISO 14001 vanno espletate da
                        organismi/società competenti in materia.
                      </p>
                      <p>
                        Noi di Manutenpul continuiamo a curare con estrema attenzione
                        tutti gli aspetti del nostro lavoro che possono influire
                        sull’ambiente, ricerchiamo macchinari e prodotti sempre
                        meno impattanti e formiamo il nostro personale per
                        essere attento alla{" "}
                        <strong>
                          qualità dei servizi da ogni punto di vista
                        </strong>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div class="certified-div">
                <img class="img-cert" alt="iso 9001" src={iso} />
              </div>
            </div>
            <div className="col-12">
              <div class="certified-div">
                <img class="img-cert-company" alt="cer company" src={cer} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default IndexPage;
